'use client'
import {useEffect, useRef} from "react";
import {useParams} from "next/navigation";

const ScrollIntoView = ({anchorNaam}) => {
    const elem = useRef()
    const params = useParams();

    useEffect(() =>{
        if (typeof window === "undefined") return;
        if(window?.location?.hash === anchorNaam){
            elem.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        } else {
          //  window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    },[params])


    return <div ref={elem}></div>
}
export default ScrollIntoView;